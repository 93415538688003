import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Box } from "@chakra-ui/react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="JVP Labs" />
    <Box p={8}>
      <StaticImage
        src="../images/jvp-labs-logo.png"
        width={300}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="JVP Labs logo"
        style={{ marginBottom: `1.45rem` }}
      />
    </Box>
  </Layout>
)

export default IndexPage
